.root {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	min-height: 460px;
}

.demosWrapper {
	z-index: 1;
	max-width: 100%;
	padding-bottom: 60px;
}
