.root {
	padding-top: 30px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 70%;
	z-index: 2;
}

.logosGridStrip {
	display: flex;
	max-width: 100%;
	gap: 22px;
}

.logosGridStrip:hover {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.rotateContainer {
    display: flex;
    animation: rotateStrip 20s linear infinite;
}
  
@keyframes rotateStrip {
    0% {
      transform: translateX(0%);
    }

    50% {
        transform: translateX(-80%);
    }

    100% {
      transform: translateX(0%);
    }
}

@media only screen and (max-width: 800px) {
	@keyframes rotateStrip {
		0% {
		  transform: translateX(0%);
		}
	
		50% {
			transform: translateX(-350%);
		}
	
		100% {
		  transform: translateX(0%);
		}
	}

	.rotateContainer {
		display: flex;
		animation: rotateStrip 20s linear infinite;
	}
}

@media only screen and (max-width: 500px) {
	.root {
		padding-top: 50px;
	}
}

@media only screen and (max-width: 1310px) {
	.logosGrid {
		gap: 15px;
		flex-wrap: nowrap;
	}
}
