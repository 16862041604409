.root {
	display: flex;
	gap: 10px;
	overflow-x: scroll;
	justify-content: flex-start;
	padding-bottom: 5px;
}

.root::-webkit-scrollbar-thumb {
	background-color: rgb(196, 196, 196);
	border-radius: 12px;
}

.root::-webkit-scrollbar {
	background-color: transparent;
	height: 2px;
}

.card {
	background: #ebebeb;
	border-radius: 5px;
	padding: 2px 8px;
	font-size: 13px;
	width: 176px;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
}

.cardSelected {
	background: #e02189;
	color: #fff;
}

@media only screen and (max-width: 700px) {
	.root::-webkit-scrollbar {
		height: 0px;
	}
}
