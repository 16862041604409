.root {
	padding-top: 60px;
}

.selectedDemo {
	display: flex;
	align-items: center;
	margin-top: 20px;
	height: 420px;
	gap: 40px;
	justify-content: center;
}

.bg {
	position: absolute;
	width: 100%;
	left: 0;
	height: 420px;
}

.info,
.image {
	z-index: 1;
}

.image {
	position: relative;
	overflow: hidden;
	width: 50%;
	height: 352px;
	border-radius: 12px;
}

.info {
	display: flex;
	flex-direction: column;
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	color: #333333;
	margin-bottom: 30px;
}

.desc {
	max-width: 400px;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #666666;
	margin-bottom: 20px;
}

@media only screen and (max-width: 700px) {
	.selectedDemo {
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: flex-start;
		padding: 24px 16px;
		height: 100%;
		min-height: 500px;
	}

	.image {
		width: 100%;
		height: 180px;
	}

	.bg {
		display: none;
	}

	.info {
		justify-content: center;
		align-items: center;
	}

	.desc {
		font-size: 14px;
		text-align: left;
	}
}
