.root {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: 460px;
}

.title {
	z-index: 1;
}

.content {
	z-index: 1;
}

.bullet {
	color: #e02189;
	display: inline-block;
	margin-right: 9px;
}

.content {
	display: flex;
	gap: 100px;
	margin-top: 48px;
}

.info {
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 60px;
}

.imageGroup {
	width: 40%;
}

.image {
	z-index: 1;
	position: relative;
	max-width: 380px;
	height: 400px;
}

@media only screen and (max-width: 1200px) {
	.content {
		flex-direction: column;
		align-items: center;
		gap: 40px;
	}

	.info {
		width: 100%;
	}

	.imageGroup {
		width: 100%;
	}

	.image {
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (max-width: 500px) {
	.image {
		height: 220px;
		max-width: 200px;
	}
}
