.root {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: 460px;
}

.card {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	width: 100%;
	border-radius: 12px;
	z-index: 1;
	min-height: 350px;
}

.cardBody {
	position: absolute;
	padding: 36px 24px;
	z-index: 10;
	right: 40px;
	max-width: 60%;
	background: #ffffffe8;
	border-radius: 12px;
}

.imageBg {
	object-fit: cover;
  border-radius: 12px;
}

@media only screen and (max-width: 500px) {
	.cardBody {
    position: relative;
		max-width: 100%;
		right: unset;
		bottom: 0;
		background: #ffff;
		border-radius: 0px 0px 12px 12px;
	}

	.card {
		flex-direction: column;
	}
  .imageContainer {
    position: relative;
    height: 200px;
    width: 100%;
  }
  .imageBg {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
