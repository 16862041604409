.pageTitle {
	font-weight: 700;
	font-size: 28px;
	line-height: 120%;
	color: #333333;
}

.title {
	font-weight: 700;
	font-size: 18px;
	line-height: 120%;
	color: #333333;
}

.description {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #666666;
}

@media only screen and (max-width: 700px) {
	.pageTitle {
		font-size: 20px;
	}

	.title {
		font-size: 16px;
	}

	.description {
		font-size: 14px;
	}
}
