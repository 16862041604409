.root {
	display: flex;
}

.bg {
	position: absolute;
	height: 720px;	
	width: 100%;
}

.blurBg {
	position: absolute;
	width: 60%;
	height: 720px;
	backdrop-filter: blur(5px);
	z-index: 1;
}

.partnersWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.leftWrapper {
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 720px;
}

.title {
	font-weight: 700;
	font-size: 64px;
	color: #FFF;
	text-align: center;
	width: 100%;
	z-index: 1;
	margin: 0;
}

.subtitle {
	font-weight: 400;
	font-size: 18px;
	color: #FFF;
	margin-top: 16px;
	margin-bottom: 32px;
	z-index: 1;
	text-align: center;
}

.integration {
	color: #FFF;
	z-index: 1;
}

.leftContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
}

.buttons {
	display: flex;
	gap: 20px;
	margin-bottom: 48px;
	margin-top: 0;
	width: 400px;
	z-index: 2;
}

.laptopWrapper {
	position: relative;
	z-index: 10;
	overflow: hidden;
	width: 560px;
	height: 325px;
}

@media only screen and (max-width: 1200px) {
	.root {
		flex-direction: column-reverse;
		min-height: unset;
	}

	.leftWrapper,
	.rightWrapper {
		align-items: center;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.imageBg {
		width: 400px;
		height: 400px;
	}

	.laptopWrapper {
		width: 460px;
		height: 240px;
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 915px) {
	.blurBg {
		width: 100%;
		height: 400px;
	}
}


@media only screen and (max-width: 600px) {
	.imageBg {
		width: 300px;
		height: 300px;
	}

	.laptopWrapper {
		width: 300px;
		height: 150px;
	}

	.title {
		font-size: 24px;
	}

	.subtitle {
		font-size: 16px;
	}

	.buttons {
		margin-top: 40px;
	}
}

@media only screen and (max-width: 415px) {
	.buttons {
		flex-direction: column;
		width: 50%;
	}

	.blurBg {
		height: 450px;
	}
}

