.root {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: 460px;
}

.card {
	position: relative;
	display: flex;
	width: 100%;
	height: 288px;
	gap: 160px;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.icon {
	z-index: 1;
}

.content {
	z-index: 1;
}

.title {
	max-width: 390px;
	color: #fff;
}

.form {
	display: flex;
	gap: 40px;
	width: 100%;
}

.input {
	height: 42px;
	min-width: 250px;
}

.button {
	background: #32042d;
	height: 42px;
	min-width: 130px;
}

.button:active,
.button:focus {
	background: #32042d;
}

.button:hover {
	background: #4e0645;
}

.errorMessage {
	color: #d4ff00;
}

.error {
	margin-top: 10px;
}

@media only screen and (max-width: 1000px) {
	.card {
		padding-top: 40px;
		padding-bottom: 40px;
		flex-direction: column;
		height: 100%;
		gap: 10px;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.form {
		margin-top: 30px;
		flex-direction: column;
		align-items: center;
		gap: 0px;
		max-width: 200px;
	}
}

@media only screen and (max-width: 500px) {
	.title {
		max-width: 250px;
	}
}
