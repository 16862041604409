.root {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}

.carousel {
	width: 100%;
	height: 100%;
	max-width: 80vw;
}

.card {
	padding: 0px 0px 24px;
	max-width: 263px;
	background: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 12px;
	text-align: center;
	cursor: pointer;
}

.card:hover {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.image {
	position: relative;
	overflow: hidden;
	width: 260px;
	height: 138px;
	border-radius: 12px 12px 0px 0px;
}

.name {
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	color: #4d4d4d;
	margin-bottom: 0px;
	padding-top: 20px;
}

.icon {
	font-size: 40px;
	color: #e02189;
	margin-top: -7.4px;
}

.button {
	padding-top: -10px;
	border-radius: 100px;
}

.root .carousel .carouselContent {
	display: flex !important;
	width: 100%;
	gap: 16px;
	justify-content: center !important;
	padding-left: 5px;
	padding-right: 5px;
}

.controlsLeft {
	position: absolute;
	left: -40px;
}

.controlsRight {
	position: absolute;
	right: -50px;
}

.selected {
	border: 1px solid #e02189;
}

@media only screen and (max-width: 750px) {
	.controlsLeft {
		left: 0;
	}

	.controlsRight {
		right: 0;
	}
}

@media only screen and (min-width: 2559px) {
	.carousel {
		display: flex !important;
		justify-content: center;
		max-width: 1408px;
	}
}
