.root {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: 460px;
}

.title {
	z-index: 1;
}

.top {
	display: flex;
	justify-content: space-between;
	z-index: 1;
}

.steps {
	margin-top: 60px;
	display: flex;
	gap: 50px;
	z-index: 1;
}

.stepCard {
	display: flex;
	flex-direction: column;
	gap: 16px;
	min-width: 200px;
}

.stepWrapper {
	display: flex;
	align-items: center;
	gap: 30px;
	min-height: 49px;
}

.step {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #e02189;
	border-radius: 100px;
	min-width: 32px;
	text-align: center;
	font-size: 16px;
	line-height: 140%;
	height: 32px;
	color: #fff;
}

.line {
	min-width: unset;
	width: 100%;
	border-color: #e02189;
}

@media only screen and (max-width: 1200px) {
	.steps {
		flex-direction: column;
	}

	.stepCard {
		flex-direction: row;
	}

	.stepWrapper {
		flex-direction: column;
	}

	.line {
		transform: rotate(90deg);
	}
}
