.root {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: 700px;
}

.left,
.right {
	z-index: 1;
	width: 50%;
}

.left {
	display: flex;
	justify-content: center;
}

.imageWrapper {
	position: relative;
	width: 500px;
	height: 500px;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 450px;
}

.mobile {
	display: none;
}

@media only screen and (max-width: 1185px) {
	.root {
		flex-direction: column;
	}

	.right {
		display: none;
	}

	.mobile {
		display: block;
		margin-bottom: 10px;
	}

	.button {
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
	}

	.imageWrapper {
		width: 400px;
		height: 400px;
	}
}

@media only screen and (max-width: 460px) {
	.imageWrapper {
		width: 320px;
		height: 320px;
	}
}
