.root {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 60px;
	min-height: 460px;
}

.grid {
	display: flex;
	flex-wrap: wrap;
	gap: 60px;
	width: 100%;
	margin-top: 48px;
	z-index: 1;
}

.title {
	z-index: 1;
}

.icon {
	display: flex;
	align-items: center;
	height: 72px;
	text-align: center;
}

.card {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0px;
	gap: 50px;
	width: 45%;
}

@media only screen and (max-width: 780px) {
	.card {
		width: 100%;
	}
}
