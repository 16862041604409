.whiteBg,
.grayBg {
	position: absolute;
	width: 99.3vw;
	left: -10vw;
	height: 100%;
}

.grayBg {
	background: #f5f5f5;
}

.whiteBg {
	background: #ffff;
}

@media only screen and (min-width: 2500px) {
	.whiteBg,
	.grayBg {
		max-width: 2560px;
	}
}

@media only screen and (max-width: 750px) {
	.whiteBg,
	.grayBg {
		left: -2vw;
		width: 98.6vw;
	}
}
